.TrimsInfoPopup {
  border-radius: 10px;
}

.TrimsInfoPopup .mapboxgl-popup-content {
  border-radius: 10px;
}

.TrimsInfoPopup .mapboxgl-popup-close-button {
  display: none;
}

.TrimsInfoPopup .mapboxgl-popup-tip {
  display: none;
}


.ShareNavBar__warning {
  margin-left: auto;
  max-width: 600px;
  background: #f9f9f9;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  padding: 12px 21px;
  color: #666;

  align-self: stretch;
  display: flex;
  align-items: center;
  margin-left: auto;
}

  .ShareNavBar__warning svg {
    flex-shrink: 0;
    margin-right: 10px;
    color: currentColor;
  }

@media (max-width: 820px) {
  .ShareNavBar__warning {
    height: auto;
    margin: 0;
    max-width: unset;
  }
}

#parameters-aside h4 {
  margin: 16px 0 4px 0;
}
#parameters-aside h4:first-child {
  margin-top: 0;
}

.AnalysisMap--vtype {
  font-family: var(--alt-font-family);
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 18px 18px;
  cursor: pointer;
  transition-duration: 0.1s;
  color: #555;
}

.AnalysisMap--vtype .Badge {
  margin-left: 7px;
}

.AnalysisMap--vtype > svg {
  margin-right: 8px;
  color: #888;
}

.AnalysisMap--vtype:hover {
  background: #f5f5f5;
}

.AnalysisMap--vtype:active {
  background: #f0f0f0;
}

.AnalysisMap-parameters-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.AnalysisMap__CancelButton {
  margin-top: 1rem;
}

.AnalysisMap__Modal.RENAMING_REGIONS {
  max-width: 1100px;
}

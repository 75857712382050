.ViewNavBar {
  height: 60px;
  position: relative;
  display: flex;
  padding: 0 15px;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
}

.ViewNavBarTitle {
  font-size: 22px;
  font-family: var(--alt-font-family);
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ViewNavBarBack {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: -15px;
  padding: 0 15px;
  cursor: pointer;
  overflow: hidden;
}

.ViewNavBarBack:hover {
  background: #f5f5f5;
}

.NavBarItem {
  font-size: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #848484;
  text-transform: uppercase;
}

.NavBarItem__icon {
  font-size: 18px;
  margin-right: 10px;
  color: #777;
}

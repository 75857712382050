.lluiStack {
  display: flex;
  box-sizing: border-box;
}

.lluiStack > * {
  margin: 0;
}

.lluiStack--row-xss > *:not(:last-child) {
  margin-right: 1px;
}
.lluiStack--row-xs > *:not(:last-child) {
  margin-right: 2px;
}
.lluiStack--row-s > *:not(:last-child) {
  margin-right: 3px;
}
.lluiStack--row-m > *:not(:last-child) {
  margin-right: 5px;
}
.lluiStack--row-l > *:not(:last-child) {
  margin-right: 8px;
}
.lluiStack--row-xl > *:not(:last-child) {
  margin-right: 12px;
}
.lluiStack--row-xxl > *:not(:last-child) {
  margin-right: 24px;
}

.lluiStack--column-xss > *:not(:last-child) {
  margin-bottom: 1px;
}
.lluiStack--column-xs > *:not(:last-child) {
  margin-bottom: 2px;
}
.lluiStack--column-s > *:not(:last-child) {
  margin-bottom: 3px;
}
.lluiStack--column-m > *:not(:last-child) {
  margin-bottom: 5px;
}
.lluiStack--column-l > *:not(:last-child) {
  margin-bottom: 8px;
}
.lluiStack--column-xl > *:not(:last-child) {
  margin-bottom: 12px;
}
.lluiStack--column-xxl > *:not(:last-child) {
  margin-bottom: 24px;
}

.MapFlowsDetails {
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  width: 300px;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

  .MapFlowsDetails .MapFlowsDetails--list-container {
    position: relative;
    overflow-x: auto;
    background-color: #fff;
    margin-top: -1px;

    max-height: calc(90vh - 400px);
  }

  .MapFlowsDetails .MapFlowsDetails--list-container ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
    }

  .MapFlowsDetails .MapFlowsDetails--list-container ul li {
        padding: 8px 15px;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        cursor: pointer;
        font-size: 14px;
      }

  .MapFlowsDetails .MapFlowsDetails--list-container ul li:hover {
        background-color: #fafafa;
      }

  .MapFlowsDetails .MapFlowsDetails--list-container ul .MapFlowsDetails__list-item--hovered {
        background-color: #fafafa;
      }

  .MapFlowsDetails__list-item--hovered {
    background-color: #fafafa;
  }
  
  .MapFlowsDetails .MapFlowsDetails--actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }

  .MapFlowsDetails .MapFlowsDetails--actions > * {
    width: 100%
  }

  .MapFlowsDetails .MapFlowsDetails--name {
    font-size: 18px;
    font-family: var(--alt-font-family);
  }

  .MapFlowsDetails .MapFlowsDetails--label {
    font-size: 14px;
    text-transform: uppercase;
    color: #555;
  }

  .MapFlowsDetails .MapFlowsDetails--region-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px 15px;
    border-bottom: 1px solid #eee;
    min-height: 51px;
  }

  .MapFlowsDetails--region-container > svg:first-child {
    margin-right: 8px;
    color: #bbb;

  }


  .MapFlowsDetails .MapFlowsDetails--region-container:last-of-type {
    border-bottom: none;
  }

  .MapFlowsDetails .icon-smaller {
    transform: scale(0.75);
  }

  .MapFlowsDetails .MapFlowsDetails--toggle {
    margin-left: auto;
  }

  .MapFlowsDetails .MapFlowsDetails--toggle svg {
      margin-right: 0;
    }

  .MapFlowsDetails .MapFlowsDetails--list-header {
    display: flex;
    flex-shrink: 0;
    font-size: 14px;
    padding: 5px 15px 10px 15px;
    border-bottom: 2px solid #eee;
    font-family: var(--alt-font-family);

    justify-content: space-between;
  }

  .MapFlowsDetails .MapFlowsDetails--remove {
    margin-left: auto;
    padding: 5px;
    margin-right: -5px;
    cursor: pointer;
  }

  .MapFlowsDetails .MapFlowsDetails--remove svg {
      color: #bbb;
      margin-right: 0;
    }

  .MapFlowsDetails .MapFlowsDetails--remove:hover svg {
      color: #666;
    }

  .MapFlowsDetails .MapFlowsDetails__interactive {
    cursor: pointer;
  }

  .MapFlowsDetails .MapFlowsDetails__interactive:hover {
    background-color: #fafafa;
  }

.MapFlowsDetails--picker-arrow {
  position: absolute;
  right: 0;
  top: 15px;
  margin-right: -10px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #fff;
  z-index: 1;
}

.MapFlowsDetails--picker-arrow__origin {
  top: 20px;
}

.MapFlowsDetails--picker-arrow__destination {
  top: 70px;
}

.MapFlowsDetails--picker-arrow__lower {
  top: 82px;
}

.MapFlowsDetails--picker {
  position: absolute;
  right: 0;
  font-size: 14px;
  width: 320px;
  margin-right: -330px;
  background-color: #fff;

  padding-bottom: 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
}

.MapFlowsDetails--picker .MapFlowsDetails--picker-search {
    padding: 15px 15px 10px 15px;
    display: flex;
    align-items: center;
  }

.MapFlowsDetails--picker .MapFlowsDetails--picker-search .Input__wrapper {
      flex-grow: 1;
      width: 0;
    }

.MapFlowsDetails--picker .MapFlowsDetails--picker-remove {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: -5px;
    padding: 8px 9px 9px 9px;
    margin-left: 4px;
    cursor: pointer;
  }

.MapFlowsDetails--picker .MapFlowsDetails--picker-remove:hover {
      background: rgba(0, 0, 0, 0.06);
    }

.MapFlowsDetails--picker .MapFlowsDetails--picker-remove > svg {
      color: #888;
      margin-right: 0;
    }

.MapFlowsDetails--picker .MapFlowsDetails--picker-remove:hover .fa {
      color: rgba(0, 0, 0, 0.5);
    }

.MapFlowsDetails--picker .MapFlowsDetails--picker-list {
    max-height: calc(50vh);
    overflow-y: auto;
  }

.MapFlowsDetails--picker .MapFlowsDetails--picker-list-item {
    font-size: 14px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MapFlowsDetails--picker-list-item > span >.MapFlowsDetails__zoom-to-region{
    opacity: 0;
  }


  .MapFlowsDetails__list-item--hovered > span >.MapFlowsDetails__zoom-to-region {
    opacity: 1;
  }



.MapFlowsDetails__toggle {
  position: absolute;
  right: -24px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  width: 24px;
  height: 24px;
  color: #fff;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.3);
  transform-origin: bottom right;
  cursor: pointer;
  transition-duration: 0.1s;
}

.MapFlowsDetails__toggle .fa {
    margin-top: -2px;
  }

.MapFlowsDetails__toggle:hover {
  background: rgba(0, 0, 0, 0.4);
}

.MapFlowsDetails--hidden {
  transform: translateX(-312px);
}

@media (max-width: 550px) {
  .MapFlowsDetails {
    display: none;
  }
}


.RenameRegions {
  display: flex;
  height: 70vh;
}

.RenameRegions__list {
  overflow-y: auto;
}

.RenameRegions__region {
  width: 260px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.RenameRegions__region:hover {
  background: #f9f9f9;
}

.RenameRegions__map {
  width: 50vw;
  height: 100%;
  display: flex;
  position: relative;
}
.RenameRegions__side {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 25px;
}
.RenameRegions__side h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.RenameRegions__actions {
  margin-top: auto;
  padding-top: 1rem;
}

.RegionSelector__modal {
  width: 100%;
  min-width: 700px;
  padding: 30px;
}

.RegionSelector__column-header {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.RegionSelector__column-header h3 {
  margin: 0 !important;
}

.RegionSelector__column-header div {
  margin-left: auto;
}

.RegionSelector__container {
  display: flex;
}

.RegionSelector__column {
  width: 50%;
}

.RegionSelector__column:first-child {
  margin-right: 30px;
}

.RegionSelector__list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-top: 5px;
}

.RegionSelector__list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  padding: 0 8px;
  font-size: 14px;
  height: 42px;
  font-family: var(--alt-font-family);
}

.RegionSelector__list-item:last-child {
  border-bottom: none;
}

.RegionSelector__list-item--selected {
  background-color: rgba(0, 0, 0, 0.03);
}

.RegionSelector__close {
  background: transparent;
  border: none;
  position: absolute;
  padding: 20px;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: #888;
  user-select: none;
}

.RegionSelector__icon {
  width: 20px;
  color: #333;
}

.RegionSelector__icon svg {
  stroke-width: 3px;
}

.RegionSelector__list-message {
  text-align: center;
  padding: 10px 0;
}

.RegionSelector__column .Input__field {
  max-width: unset;
}

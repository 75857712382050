.analysis-item:hover .analysis-item-actions > * {
  opacity: 1;
}

.analysis-item-actions {
  display: flex;
  gap: 4px;
}

.analysis-item-actions > * {
  opacity: 0;
}

.analysis-item:not(.analysis-list-header) {
  cursor: pointer;
}

.analysis-item:not(.analysis-list-header):hover {
  background: rgba(0, 0, 0, 0.01);
}
.analysis-item {
  text-decoration: none;
  justify-content: space-between;
  background: #fff;
  height: 55px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  border: solid 1px #e5e5e5;
  margin-top: -1px;

  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;

  cursor: pointer;
}

.analysis-item-duration {
  padding: 3px 0;
  width: 180px;
  display: flex;
  flex-direction: column;
}

.analysis-item-duration .analysis-item-duration-label {
  display: flex;
  align-items: center;
}

.analysis-item-duration .analysis-item-duration-label svg {
  color: #666;
  margin-right: 5px;
  margin-top: -2px;
}

.analysis-item-duration .analysis-item-estimated-duration {
  color: #777;
  margin-top: 3px;
}

@media (max-width: 900px) {
  .analysis-item-duration {
    display: none;
  }
}

.analysis-item-date {
  padding: 3px 0;
  margin-right: 30px;
  width: 180px;
  display: flex;
  align-items: center;
}

.analysis-item-date svg {
  color: #666;
  margin-right: 8px;
  margin-top: -2px;
}

.analysis-item .Badge {
  margin-left: 5px;
}

.analysis-item-selected {
  margin: 10px -10px;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.05);
}

.analysis-item-disabled .analysis-item {
  color: #777;
}

.analysis-item-status {
  width: 180px;
  height: 28px;
  padding: 0;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.analysis-item-type {
  font-size: 11px;
  color: #888;
  margin-bottom: 4px;
}

.analysis-item-name-wrapper {
  flex: 2;
  padding: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: 500;
}

.analysis-item-name:hover {
  border-color: #8dc3eb;
}

.analysis-item-owner {
  white-space: nowrap;
  margin-right: 15px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.analysis-item-owner svg {
  flex-shrink: 0;
  margin-right: 5px;
  stroke: #666;
}

.analysis-item-owner-email {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 900px) {
  .analysis-item-owner {
    display: none;
  }
}

.analysis-item-params {
  padding: 10px;
  display: flex;
  width: 215px;
}

.analysis-item-params-date {
  margin: 0 10px;
}

.analysis-item-param {
  display: inline-block;
  width: 42px;
  font-weight: 500;
}

.analysis-more {
  padding: 10px;
  width: 160px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Favorite--active svg {
  fill: currentColor;
}

.AnalysisListItem__cancel-modal {
  padding: 30px;
}

.AnalysisListItem__cancel-modal p {
  margin-bottom: 25px;
  line-height: 1.4;
}

.AnalysisListItem__cancel-modal .button {
  margin-right: 10px;
}

.AnalysisListItem__cancel-modal h1 {
  margin-top: 0;
}

.AnalysisListItem__clone-modal {
  padding: 30px;
}

.AnalysisListItem__clone-modal p {
  margin-bottom: 25px;
  line-height: 1.4;
}

.AnalysisListItem__clone-modal ul {
  overflow-y: auto;
  max-height: 250px;
  width: 100%;
}

@media (max-width: 550px) {
  .analysis-item-date {
    display: none;
  }

  .analysis-item-actions > * {
    opacity: 1;
  }
}

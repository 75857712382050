.MapAsideSection {
  background-color: #fff;
  position: relative;
}

.MapAsideSection--hidable .MapAsideSection__title:hover {
  background-color: #fafafa;
}

.MapAsideSection--hidable .MapAsideSection__title:active {
  background-color: #f8f8f8;
}

.MapAsideSection__title {
  display: flex;
  align-items: center;
  font-family: var(--alt-font-family);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  color: #555;
  padding: 18px 18px;
  padding-bottom: 0;
}

.MapAsideSection--hidable .MapAsideSection__title {
  padding-bottom: 18px;
  cursor: pointer;
}

.MapAsideSection--no-shrink {
  flex-shrink: 0;
}

.MapAsideSection__content {
  line-height: 1.4;
  color: #666;
  padding: 18px 18px;
  padding-top: 3px;
  padding-bottom: 20px;
}

.MapAsideSection--info {
  background: rgba(0, 75, 127, 0.04);
}

.MapAsideSection--info .MapAsideSection__title,
  .MapAsideSection--info .MapAsideSection__title svg {
    color: #004b7f;
  }

.MapAsideSection--info .MapAsideSection__content {
    color: #004b7f;
  }

.MapAsideSection--error {
  background: hsla(2, 46%, 98%, 1);
}

.MapAsideSection--warning {
  background: rgba(249, 176, 35, 0.05);
}

.MapAsideSection--warning .MapAsideSection__content,
  .MapAsideSection--warning .MapAsideSection__title,
  .MapAsideSection--warning .MapAsideSection__title svg {
    color: #f9b023;
  }

.MapAsideSection__title-icon {
  display: flex;
  margin-right: 8px;
  color: #6d6d6d;
}

.MapAsideSection--flex {
  display: flex;
  flex-direction: column;
}

.MapAsideSection__toggle {
  margin-left: auto;
  opacity: 0.6;
  transition-duration: 0.1s;
}

.MapAsideSection:hover .MapAsideSection__toggle {
  opacity: 0.9;
}

#view-page {
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#view-page + footer .Footer__copyright {
  height: 30px;
  background: #fff;
  border-top: 1px solid #eee;
}

#analysis-loading {
  animation: reveal;
  animation-duration: 2s;
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#content-grid,
.dashboard-container {
  animation: reveal;
  animation-duration: 0.2s;
}

.analysis-name {
  text-transform: uppercase;
  font-family: var(--alt-font-family);
  font-weight: 700;
  font-size: 26px;
  margin-left: 15px;
  line-height: 62px;
}

#view-page-name {
  transition-duration: 0.2s;
  display: flex;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  height: 60px;
  text-transform: uppercase;
  padding-left: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  z-index: 999;
}

#view-page-name .circle {
  margin: 0;
}

.error-container {
  max-width: 720px;
  margin: 20px auto;
}

#view-page-container {
  display: flex;
  height: 100%;
  left: 0;
  right: 0;
}

#view-page-info {
  padding: 15px;
  width: 250px;
  border-right: 1px solid #eee;
}

#view-page .Tabs__content {
  position: relative;
  height: calc(100vh - 190px);
  background-color: #fff;
  border: 1px solid #eceeef;
  margin-bottom: 0px;
  border-top: 0;
}

.view-page-preloader {
  width: 100%;
  height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
}

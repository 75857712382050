.SliderInput {
  max-width: 350px;
  width: 100%;
  margin-top: -10px;
  font-family: var(--font-family);
}

.SliderInput__content {
  display: flex;
  align-items: center;
  height: 40px;
}

.SliderInput__label {
  font-weight: bold;
  font-size: 0.9em;
}

.SliderInput .Input__wrapper {
  width: 80px;
}

.SliderInput .Range {
  margin-top: -4px;
  flex-grow: 1;
  margin-right: 10px;
}

.SliderInput input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #2a90d5;
  cursor: pointer;
  box-shadow: none;
}

.SliderInput input[type='range']::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #2a90d5;
  cursor: pointer;
  box-shadow: none;
}

.SliderInput input[type='range']::-ms-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #2a90d5;
  cursor: pointer;
  box-shadow: none;
}

.FullscreenError__wrapper {
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.FullscreenError {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  border: solid 1px #e7e7e7;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.FullscreenError h1 {
  font-family: var(--alt-font-family);
  font-weight: 700;
}

.FullscreenError p {
  font-size: 16px;
  max-width: 400px;
  text-align: center;
}

.FullscreenError__actions {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  margin-top: 20px;
}

.FullscreenError__contacts {
  font-size: 12px;
  color: #666;
  margin-top: 40px;
}

.FullscreenError__status {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

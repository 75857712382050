.AnalysisSummary {
  padding: 30px;
  width: 480px;
}

.AnalysisSummary h2 {
  margin-top: 0;
}

.AnalysisSummary__actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.AnalysisSummary__dangerousButtons {
  margin-right: auto;
}

.AnalysisSummary__dangerousButtons > * {
  margin-right: 8px;
}

.HeatMapLine__labels {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 3px;
}

.HeatMapLine {
  position: absolute;
  bottom: 20px;
  background: linear-gradient(
    to right,
    hsl(100, 50%, 49%),
    hsl(90.4, 60%, 49%),
    hsl(80.8, 70%, 49%),
    hsl(71.2, 80%, 49%),
    hsl(61.6, 90%, 49%),
    hsl(52, 90%, 49%),
    hsl(42.400000000000006, 90%, 49%),
    hsl(32.8, 90%, 49%),
    hsl(23.200000000000003, 90%, 49%),
    hsl(13.600000000000009, 90%, 49%),
    hsl(4, 90%, 49%)
  );
  left: 20px;
  opacity: 1;
  width: 350px;
  height: 10px;
  border: 1px solid 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 2px 16px;
  color: #fff;
  font-family: var(--alt-font-family);
  font-weight: 700;
  font-size: 15px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

.HeatMapLine__block {
  position: relative;
  bottom: unset;
  left: unset;
  width: 100%;
}

.ColorLegend {
  position: absolute;
  z-index: 1;
  bottom: 25px;
  right: 10px;
  background: #fff;
  padding: 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.ColorLegend-Range {
  display: flex;
  justify-content: space-between;
}

.ColorLegend-Range-box {
  width: 30px;
  opacity: 0.8;
}
.ColorLegend-Range-values {
  margin: 4px;
  font-size: 12px;
}

@media (max-width: 550px) {
    .ViewPageContent .ColorLegend {
      display: none;
    }
}

.Option {
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-family: var(--alt-font-family);
  font-weight: 400;
  width: 100%;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 2px;
  margin: 3px;
  text-align: center;
  padding: 0.5em 1em;
  color: #55595c;
}

.Option:not(.Option--selected):hover {
  background-color: darken(#f9f9f9, 2%);
}

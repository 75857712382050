.LinkButton {
  display: flex;
  align-items: center;
  border: 0;
  font-size: 14px;
  font-family: var(--alt-font-family);
  padding: 8px 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  color: #333;
  text-transform: uppercase;
}

.LinkButton > svg {
  margin-right: 5px;
}

.ResultSpinner__wrapper {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.25);
  animation-name: spinner-appear;
  animation-fill-mode: both;
  animation-delay: 0.3s;
  animation-duration: 0.15s;
  top: 0;
  left: 0;
}

.ResultSpinner__wrapper--opaque {
  background: rgba(250, 250, 250, 1);
}

.ResultSpinner {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ResultSpinner .MapPreloader__background {
    color: #babfc3;
  }

.ResultSpinner--map {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

@keyframes spinner-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ResultSpinner__subtitle {
  font-size: 12px;
  margin-top: 12px;
  color: #666;
}

.Matrix {
  flex-grow: 1;
  position: relative;
}
.Matrix__wrapper {
  position: relative;
  height: 100%;
  margin-top: 30px;
  margin-left: 30px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
}

.Matrix__item {
  text-align: right;
  padding: 5px;
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  font-family: var(--alt-font-family);
}

.Matrix__item-number {
  font-family: sans-serif;
  font-variant: tabular-nums;
  color: #555;
}

.Matrix__item-icon {
  flex-shrink: 0;
}

.Matrix__item-title {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Matrix__item-left-header {
  justify-content: flex-end;
  text-align: right;
  padding-right: 5px;
  background: #fff;
  border-right: 1px solid #eee;
}

.Matrix__item-top-header {
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.Matrix__item-top-header:hover {
  background: rgba(0, 0, 0, 0.03);
}

.Matrix__item-top-header,
.Matrix__item-left-header {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Matrix__horizontal-title,
.Matrix__vertical-title {
  position: absolute;
  font-size: 15px;
  padding: 5px;
  z-index: 1;
  color: #969696;
  text-transform: uppercase;
}

.Matrix__horizontal-title {
  left: 40px;
}

.Matrix__vertical-title {
  top: 40px;
  transform-origin: top left;
  transform: rotate(-90deg) translateX(-100%);
}

.colored-matrix-parameters-warning {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  color: #6b95c7;
}

.colored-matrix-parameters-warning > svg {
    margin-right: 6px;
  }

:root {
  --thumb-size: 15px;
}

.Range__selector {
  position: relative;
  font-size: 12px;
}

.Range__selector-min,
.Range__selector-max,
.Range__selector-value {
  position: absolute;
  margin-top: 5px;
  color: #ddd;
}
.Range__selector-max {
  right: 0;
}
.Range__selector-value {
  z-index: 2;
  color: #666;
  background: #fff;
  will-change: left, transform;
}

/* Input styling */
input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  margin: 0;
  outline: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  margin-top: -5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);
}
input[type='range']::-moz-range-thumb {
  border: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);
}
input[type='range']::-ms-thumb {
  border: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background: #e5e5e5;
  cursor: pointer;
  border: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  outline: none;
  background: #e5e5e5;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background: #e5e5e5;
  cursor: pointer;
}
input[type='range']:focus::-moz-range-track {
  background: #e5e5e5;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 10px 0;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #e5e5e5;
  border: 0;
  border-radius: 3px;
}
input[type='range']:focus::-ms-fill-lower {
  background: #bbb;
}
input[type='range']::-ms-fill-upper {
  background: #e5e5e5;
  border: 0;
  border-radius: 3px;
}
input[type='range']:focus::-ms-fill-upper {
  background: #bbb;
}

a.NavBarLink {
  line-height: 80px;
  text-decoration: none;
  color: #333;
  margin: 0 1rem;
  cursor: pointer;
  transition-duration: 0.1s;
  will-change: box-shadow;
  text-transform: uppercase;
}

.NavBarLink:hover,
.NavBarLink--active {
  text-decoration: none;
  color: #333;
  box-shadow: inset 0 -5px 0 0px #df1b12;
  text-shadow: 0.3px 0.3px 0 #444;
}

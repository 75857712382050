:root {
  --tombac-icons-base-size: 14;
}

.leaflet-container {
  margin: auto;
  height: 100%;
  z-index: 0;
  font-family: var(--alt-font-family);
  flex-grow: 1;
}

.button {
  transition: none !important;
}

.react-date-field--picker-position-bottom > .react-date-field__picker {
  top: 100%;
  left: -187% !important;
}

.Input__icon {
  margin-top: 0;
}

a,
a:focus,
a:hover {
  text-decoration: unset;
}

div.Tooltip {
  border-radius: 4px;
}

div.Tooltip__content {
  padding: 6px 10px;
  padding-top: 8px;
  font-family: var(--font-family);
}

* {
  box-sizing: border-box;
}

input {
  -webkit-appearance: none;
}

html {
  font-size: 14px !important;
  overflow-y: scroll;
}

body {
  font-family: var(--alt-font-family);
  background-color: rgb(250, 250, 250);
  margin: 0;
  color: #000;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

strong {
  font-family: var(--alt-font-family);
  font-weight: 500;
}

a {
  color: inherit;
}

.Menu a {
  text-decoration: none;
}

.MenuItem {
  font-size: 13px;
  padding: 12px 25px;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  margin: 4.35px 0;
  background: transparent;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 6.3px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #f5f5f5;
  border-radius: 25px;
  border: 1px solid rgba(1, 1, 1, 0.1);
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px rgba(255, 0, 0, 0.49),
    0px 0px 0px rgba(255, 26, 26, 0.49);
  border: 0px solid rgba(148, 30, 0, 0);
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #333;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.35px;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 6.3px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #f5f5f5;
  border-radius: 25px;
  border: 1px solid rgba(1, 1, 1, 0.1);
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(255, 0, 0, 0.49),
    0px 0px 0px rgba(255, 26, 26, 0.49);
  border: 0px solid rgba(148, 30, 0, 0);
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #333;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 6.3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #f0f0f0;
  border: 1px solid rgba(1, 1, 1, 0.1);
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type='range']::-ms-fill-upper {
  background: #f5f5f5;
  border: 1px solid rgba(1, 1, 1, 0.1);
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type='range']::-ms-thumb {
  box-shadow: 0px 0px 0px rgba(255, 0, 0, 0.49),
    0px 0px 0px rgba(255, 26, 26, 0.49);
  border: 0px solid rgba(148, 30, 0, 0);
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #333;
  cursor: pointer;
  height: 6.3px;
}

.Checkbox__wrapper {
  margin: 4px 0;
}

input.Input__field {
  height: 35px;
  font-size: 14px;
}

html,
body,
#layout,
#root,
#visualisation-panel,
#map-container,
.fill-height {
  position: relative;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: unset;
}

#map-container {
  display: flex;
}

.sankey-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  font-size: 15px;
  text-transform: uppercase;
  color: #4a4a4a;
}

.sankey {
  height: calc(100vh - 150px);
  display: flex;
}

.sankey-chart {
  padding: 15px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f9f9f9;
  position: relative;
}

.sankey-close {
  color: #f87871;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

div.google-visualization-tooltip {
  padding: 0;
}

.sankey-tooltip-subtitle {
  text-transform: uppercase;
  font-family: var(--alt-font-family);
  font-weight: 700;
  font-size: 11px;
  color: #666;
}

.sankey-tooltip-region-title {
  text-transform: uppercase;
  font-family: var(--alt-font-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
}

.sankey-tooltip-titles {
  display: flex;
  width: min-content;
  border-bottom: 1px solid #eee;
}

.sankey-tooltip-title {
  padding: 10px;
}

.sankey-tooltip-flows {
  background-color: #f9f9f9;
  text-align: center;
  padding: 10px;
}

.sankey-tooltip-flow {
  font-size: 24px;
  line-height: 1;
  font-family: var(--alt-font-family);
  font-weight: 700;
}

.Sankey-limit-button {
  float: right;
  margin-top: 0.4em;
}

.Sankey-list-item {
  border-bottom: 1px solid #eee;
  padding: 0.3em 15px;
  display: flex;
  align-items: center;
}

.Sankey-list-item:last-child {
  border: none;
}

.Sankey-list {
  margin: 5px -15px;
  list-style: none;
  padding: 0;
  overflow-y: auto;
}

.Sankey-list-item-button {
  text-transform: uppercase;
  padding: 0.4em;
  margin: 0 0.2em;
  cursor: pointer;
  border: 1px solid #bbb;
  color: #bbb;
}

.Sankey-list-item-button--active {
  color: #333;
  background-color: rgba(#333, 0.05);
  border: 1px solid #999;
  font-family: var(--alt-font-family);
}

.Sankey-list-item-delete {
  color: #bbb;
  margin: 0 0.4em;
  cursor: pointer;
}

.Sankey-list-item-delete:hover {
  color: #ed0f04;
}

.sankey-timeline {
  background-color: #fff;
  margin: -15px;
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
  padding: 15px;
}

.Sankey-no-data {
  padding: 15px;
  line-height: 1.4;
}

.Sankey-no-data h2 {
    font-family: var(--alt-font-family);
    font-weight: normal;
    text-transform: none;
  }

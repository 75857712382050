.TypeCard {
  width: 250px;
  cursor: pointer;
  margin: 5px;
  padding: 10px;
  background: #fff;
  box-sizing: content-box;
  position: relative;
  border: 1px solid #eee;
  transition-duration: 0.15s;
}

.TypeCard:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
}

.TypeCard:active {
  transform: translateY(0);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
}

.TypeCard--image {
  height: 250px;
  width: 250px;
  overflow: hidden;
  position: relative;
}

.TypeCard img {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.TypeCard--title {
  font-family: var(--alt-font-family);
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 15px 20px;
  padding-bottom: 5px;
  line-height: 1.1;
  margin: 0;
  background: #fff;
  width: 100%;
}

.TypeCard--description {
  padding: 20px;
  padding-top: 0;
  color: #666;
}

.ZoomInButton {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #fff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.ZoomInButton:not(.ZoomInButton--default) {
    position: absolute;
    top: 150px;
    left: 10px;
    column-gap: 10px;
}

.ZoomInButton--topRight {
    left: auto;
    right: 16px;
}

.ZoomInButton--bottomLeft {
    top: auto;
    bottom: 150px;
}

.ZoomInButton--bottomRight {
    left: auto;
    right: 16px;
    top: 550px;
}

.ZoomInButton svg {
    width: 24px;
    height: 24px;
    margin-bottom: 2px;
}

.ZoomInButton:hover {
    background-color: #f2f2f2;
}
.SankeySearch {
  position: relative;
}

  .SankeySearch .SankeySearch__loading {
    position: absolute;
    z-index: 3;
    right: 0.8em;
  }

  .SankeySearch .SankeySearch__list {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    padding-top: 5px;
    margin-top: -6px;
    border: 1px solid #dedede;
    max-height: 350px;
    overflow-y: auto;
  }

  .SankeySearch .SankeySearch__list .SankeySearch__list-item {
      padding: 0.5em 0.6em;
      cursor: pointer;
      border-top: 1px solid #eee;
    }

  .SankeySearch .SankeySearch__list .SankeySearch__list-item:first-of-type {
      border-top: none;
    }

  .SankeySearch .SankeySearch__list .SankeySearch__list-item:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

  .SankeySearch .SankeySearch__list .SankeySearch__list-item:hover {
      background-color: #fafafa;
    }

  .SankeySearch .SankeySearch__list--pending .SankeySearch__list-item {
    opacity: 0.5;
  }

  .SankeySearch .SankeySearch__empty {
    text-align: center;
    border-left: 1px solid #eee;
    padding: 0.5em 0.6em;
    font-family: var(--alt-font-family);
    color: #777;
  }

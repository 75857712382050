:root {
  --tooltip-border-radius: 0;
  --tooltip-z-index: 99999;
  --tooltip-tip-width: 16px;
  --tooltip-tip-height: 12px;
  --tooltip-warning-background-color: #dc1a28;
  --tooltip-warning-border-color: #dc1a28;
  --tooltip-warning-text-color: #ffffff;
  --tooltip-light-background-color: #ffffff;
  --tooltip-light-border-color: #f2f2f2;
  --tooltip-light-text-color: #211c1d;
  --tooltip-dark-background-color: rgba(33, 28, 29, 0.9);
  --tooltip-dark-border-color: rgba(33, 28, 29, 0.9);
  --tooltip-dark-text-color: #cccccc;
}

.Tooltip {
  position: absolute;
  border: 1px solid transparent;
  border-radius: var(--tooltip-border-radius);
  z-index: var(--tooltip-z-index);
}

.Tooltip__content {
  padding: 16px;
}

/*
 * Tooltip TIP
 */

.Tooltip__tip {
  position: absolute;
  width: var(--tooltip-tip-width);
  height: var(--tooltip-tip-height);
}
.Tooltip__tip:before,
.Tooltip__tip:after {
  position: absolute;
  display: block;
  content: '';
  border-style: solid;
  border-color: transparent;
  border-width: var(--tooltip-tip-height) calc(var(--tooltip-tip-width) / 2) 0;
}
.Tooltip__tip:before {
  bottom: 0;
}
.Tooltip__tip:after {
  bottom: 2px;
}

/*
 * Tooltip TOP
*/
.Tooltip.Tooltip--top {
  margin-top: calc(var(--tooltip-tip-height) * -1);
}
.Tooltip.Tooltip--top .Tooltip__tip {
  left: calc(50% - var(--tooltip-tip-width) / 2);
  bottom: calc((var(--tooltip-tip-height) + 1px) * -1);
}

/*
 * Tooltip RIGHT
 */
.Tooltip.Tooltip--right {
  margin-left: var(--tooltip-tip-width);
}
.Tooltip.Tooltip--right .Tooltip__tip {
  top: calc(50% - var(--tooltip-tip-height) / 2);
  left: calc((var(--tooltip-tip-width) - 1px) * -1);
  transform: rotate(90deg);
}

/*
 * Tooltip BOTTOM
 */
.Tooltip.Tooltip--bottom {
  margin-top: var(--tooltip-tip-height);
}
.Tooltip.Tooltip--bottom .Tooltip__tip {
  left: calc(50% - var(--tooltip-tip-width) / 2);
  top: calc((var(--tooltip-tip-height) + 1px) * -1);
  transform: rotate(180deg);
}

/*
 * Tooltip LEFT
 */
.Tooltip.Tooltip--left {
  margin-left: calc(var(--tooltip-tip-width) * -1);
}
.Tooltip.Tooltip--left .Tooltip__tip {
  top: calc(50% - var(--tooltip-tip-height) / 2);
  right: calc((var(--tooltip-tip-width) - 1px) * -1);
  transform: rotate(-90deg);
}

/*
 * Tooltip WARNING
 */
.Tooltip.Tooltip--warning {
  background-color: var(--tooltip-warning-background-color);
  border-color: var(--tooltip-warning-border-color);
}
.Tooltip.Tooltip--warning .Tooltip__tip:after {
  border-top-color: var(--tooltip-warning-border-color);
}
.Tooltip.Tooltip--warning .Tooltip__content {
  color: var(--tooltip-warning-text-color);
}

/*
 * Tooltip LIGHT
 */
.Tooltip.Tooltip--light {
  background-color: var(--tooltip-light-background-color);
  border-color: var(--tooltip-light-border-color);
}
.Tooltip.Tooltip--light .Tooltip__tip:before {
  border-top-color: var(--tooltip-light-border-color);
}
.Tooltip.Tooltip--light .Tooltip__tip:after {
  border-top-color: var(--tooltip-light-background-color);
}
.Tooltip.Tooltip--light .Tooltip__content {
  color: var(--tooltip-light-text-color);
}

/*
 * Tooltip DARK
 */
.Tooltip.Tooltip--dark {
  background-color: var(--tooltip-dark-background-color);
}
.Tooltip.Tooltip--dark .Tooltip__tip:before {
  border-top-color: var(--tooltip-dark-background-color);
}
.Tooltip.Tooltip--dark .Tooltip__content {
  color: var(--tooltip-dark-text-color);
}

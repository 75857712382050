.IconTooltip {
  position: relative;
  margin-left: 5px;
  z-index: 100;
}

  .IconTooltip svg {
    color: rgba(0, 0, 0, 0.5);
    font-size: 18px;
  }

  .IconTooltip svg:hover {
    color: rgba(0, 0, 0, 1);
  }

.IconTooltip__tooltip {
  width: 200px;
  right: -5px;
  margin-top: 5px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: rgba(255, 255, 255, 0.9);
  padding: 15px 15px;
  animation: tooltip_appear;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: none;
}

.IconTooltip__tooltip--right {
  right: -178px;
}

.IconTooltip__tooltip--right::before {
  left: 8px;
}

.IconTooltip__tooltip--top {
  top: -178px;
}

.IconTooltip__tooltip--top::before {
  left: 8px;
}

.IconTooltip__tooltip::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top: none;
  top: -5px;
  right: 8px;
  position: absolute;
  border-bottom-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.IconTooltip:hover .IconTooltip__tooltip {
  display: block;
}

@keyframes tooltip_appear {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

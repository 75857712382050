.RotateButton {
  position: absolute;
  color: #aaa;
  cursor: pointer;
  font-family: var(--alt-font-family);
  font-weight: 400;
  font-size: 16px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 31px;
  background: #fafafa;
}

.RotateButton:hover {
  color: #333;
}

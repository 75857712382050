.StrandPopup {
  position: absolute;
  margin: 10px;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  width: 370px;
}

.StrandPopup__region-name {
  font-size: 18px;
  font-family: var(--alt-font-family);
  font-weight: 500;
  padding: 16px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.StrandPopup__strand-stats {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
}

.StrandPopup__stat {
  color: #888;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  font-size: 13px;
  border-right: 1px solid #eee;
}
.StrandPopup__stat:last-child {
  border: none;
}

.StrandPopup__stat-value {
  margin: 0 5px;
  font-family: var(--alt-font-family);
  font-weight: 500;
  color: #444;
}

.StrandPopup__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  cursor: pointer;
}

.StrandPopup__close:hover {
  background: rgba(0, 0, 0, 0.03);
}

.DateTimeSelector {
  display: flex;
  position: relative;
}

.DateTimeSelector--map {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 123;
  background: #fff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  border-radius: 10px;
}

.DateTimeSelector .Label {
  margin-top: 0;
}

.DateTimeSelector--column {
  flex-direction: column;
}

.DateTimeSelector__space {
  width: 8px;
  height: 8px;
}

.DateTimeSelector__loading {
  left: 0;
  top: 0;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;

  animation: date_selector_appear;
  animation-delay: 0.2s;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@keyframes date_selector_appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.DateTimeSelector--map .DateTimeSelector__range-inputs {
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 10px;
}

.DateTimeSelector__range-inputs > div,
.DateTimeSelector__range-inputs > div > div {
  width: 100%;
}

.DateTimeSelector__range-title {
  margin-bottom: 2px;
  margin-top: -3px;
  font-size: 12px;
  color: #888;
}

.DateTimeSelector__compare-button {
  background-color: #f6f6f6;
  border-left: 1px solid #eee;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: var(--alt-font-family);
  font-size: 14px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.DateTimeSelector__compare-button:hover {
  background-color: #f0f0f0;
}

.DateTimeSelector--map .DateTimeSelector__range-container {
  padding: 10px 15px;
  width: 500px;
}

.DateTimeSelector__range-container {
  position: relative;
  width: 100%;
}

.DateTimeSelector__range-container--compare {
  border-left: 1px solid #eee;
}

.DateTimeSelector__range-close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fc424f;
}

.DateTimeSelector__range-close:hover {
  background-color: rgba(180, 3, 15, 0.09);
}

.DateTimeSelector__range-close:active {
  background-color: rgba(180, 3, 15, 0.25);
}

@media (max-width: 550px) {
  .DateTimeSelector__compare-button {
    display: none;
  }
}

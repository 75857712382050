.AnalysisStatusIndicator {
  color: #000;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.AnalysisStatusIndicator--rotate svg {
  animation: rotation;
  animation-timing-function: linear;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

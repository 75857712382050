.mapboxgl-popup {
  font-size: 13px;
}

.mapboxgl-popup-content {
  border-radius: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 6px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.mapboxgl-popup-title {
  margin: 0 -10px;
  margin-top: -7px;
  padding: 0 10px;
  color: #555;
  font-family: var(--alt-font-family);
  height: 35px;
  line-height: 32px;
  font-size: 16px;
}

.mapboxgl-popup-close-button {
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 0;
  border: none;
  text-align: center;
  font: 16px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: 700;
  border-radius: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5),
    #fff,
    #fff,
    #fff
  );
}

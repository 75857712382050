.MapFlows-popup {
  line-height: 1.4;
  margin: -10px;
}

  .MapFlows-popup .MapFlows-popup-title {
    padding: 10px;
    font-family: var(--alt-font-family);
    font-weight: 500;
    font-size: 16px;
  }

  .MapFlows-popup .MapFlows-popup-internal {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
  }

  .MapFlows-popup .MapFlows-popup-internal-title {
    color: #333;
  }

  .MapFlows-popup .MapFlows-popup-internal-value {
    font-family: var(--alt-font-family);
  }

  .MapFlows-popup .MapFlows-popup-actions {
    margin-bottom: var(--space-1);
    margin-top: var(--space-half);
    display: flex;
    flex-direction: column;
  }

  .MapFlows-popup .MapFlows-popup-actions .button {
      border-top: 1px solid #eee;
      margin-left: 0;
    }

  .MapFlows-popup .MapFlows-popup-description {
    padding: 12px 10px;
    font-size: 12px;
    color: #666;
  }

.MapFlowsDetails--message {
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 18px;
}

.MapFlowsDetails--message .fa {
    color: #bbb;
    font-size: 24px;
  }

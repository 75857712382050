.MapAside {
  background-color: #fff;
  width: 250px;
  border-right: 1px solid #eee;
  z-index: 10;
  overflow-y: auto;
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  z-index: 10;
}

.MapAside__gray {
  background-color: #f9f9f9;
}

.MapAside--title {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: -15px;
  margin-top: -15px;
  margin-right: -15px;
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  font-family: var(--alt-font-family);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 26px;
  word-break: break-all;
}

.MapAside--title .button {
  margin-left: auto;
}

.MapAside__toggle {
  position: fixed;
  bottom: 30px;
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  font-size: 18px;
  padding: 0 20px;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  background: #fafafa;
  font-family: var(--alt-font-family);
  text-transform: uppercase;
  display: none;
  z-index: 1001;
}

.MapAside__toggle-icon {
  transition-duration: 0.2s;
  margin-right: 10px;
}

@media (max-width: 650px) {
  .MapAside {
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100% !important;
    left: 0;
    will-change: transform;
    transform: translateY(100%);
    transition-duration: 0.3s;
  }

  .MapAside__toggle {
    display: flex;
  }

  .MapAside--open {
    top: 0;
    bottom: unset;
    transform: translateY(0px);
    padding-bottom: 70px;
  }

    .MapAside--open .MapAside__toggle {
      border-top: none;
      border-bottom: 1px solid #eee;
    }
    .MapAside--open + .MapAside__toggle .MapAside__toggle-icon {
      transform: rotate(180deg);
    }
}
